import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import About from "./About";
import Projects from "./Projects";
import Awards from "./Awards";

function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const [fadeClass, setFadeClass] = useState('opacity-0');


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowMenu(true);
        setTimeout(() => setFadeClass('opacity-100'), 300); // Trigger fade-in
      } else {
        setFadeClass('opacity-0');
        setTimeout(() => setShowMenu(false), 300); // Delay hiding to match fade-out duration
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className=' *:selection:bg-primary *:selection:text-white m-0 bg-stone-950'>
      {showMenu && (
        <div className={`fixed top-0 left-0 w-full bg-black/10 text-white p-5 flex justify-around z-50 transition-opacity duration-300 ${fadeClass} m-0`}>
          <a href="#index" className="font-raleway text-sm font-bold uppercase hover:bg-primary transition duration-500">
            Index
          </a>
          <a href="#about" className="font-raleway text-sm font-bold uppercase hover:bg-primary transition duration-500">
            About
          </a>
          <a href="#projects" className="font-raleway text-sm font-bold uppercase hover:bg-primary transition duration-500">
            Projects
          </a>
          <a href="#awards" className="font-raleway text-sm font-bold uppercase hover:bg-primary transition duration-500">
            Awards
          </a>
        </div>
      )}
      <section id="index" className="min-h-screen flex flex-col justify-center sm:items-end px-5">
        <div className='text-white sm:w-4/5 px-10 sm:px-0 text-start'>
          <p className="text-6xl md:text-9xl font-six-caps font-black text-shadow dark:text-secondary text-justify">
            RODRIGO SILVA
          </p>
        </div>
        <div className='text-white px-10 sm:p-0 sm:w-3/5 text-start'>
          <div className='text-white sm:w-2/3 text-start'>
            <p className="font-raleway text-sm font-bold uppercase text-justify">
              Enthusiastic about developing and building solutions. Student at <span>UBI</span>, Portugal. Currently, interested in the field of Data Science and ML. Looking forward to start my MSc in Computer Engineering, continuing my studies at UBI.
            </p>
          </div>

          <div className='text-white sm:w-2/3 text-start mt-5 leading-tight'>
            <div className="m-0 p-0">
              <a href="#about" className="font-raleway leading-none text-sm font-bold uppercase hover:bg-primary focus:bg-primary transition duration-500 ease-in-out mt-0 mb-0">
                about
              </a>
            </div>
            <div className="m-0 p-0">
              <a href="#projects" className="font-raleway leading-none text-sm font-bold uppercase hover:bg-primary focus:bg-primary transition duration-500 ease-in-out mt-0 mb-0">
                projects
              </a>
            </div>
            <div className="m-0 p-0">
              <a href="#awards" className="font-raleway leading-none text-sm font-bold uppercase hover:bg-primary focus:bg-primary transition duration-500 ease-in-out mt-0 mb-0">
                awards
              </a>
            </div>
          </div>

        </div>
      </section>

      <section id="about" className="flex flex-col justify-center sm:items-end px-5 min-h-screen">
        <About />
      </section>
      <section id="projects" className="flex flex-col justify-top mt-20 pt-20 sm:items-end px-5 min-h-screen">
        <Projects />
      </section>
      <section id="awards" className="flex flex-col justify-top mt-20 pt-20 px-5 min-h-screen">
        <Awards />
      </section>
    </div>
  );
}

export default Home;
