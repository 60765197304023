import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import img1 from '../assets/img/portfolio/2024estorilclassics/935k3.jpeg';
import img2 from '../assets/img/portfolio/2024estorilclassics/avon.jpg';
import img3 from '../assets/img/portfolio/2024estorilclassics/cocacola.jpeg';
import img4 from '../assets/img/portfolio/2024estorilclassics/giuliasprintgta.jpeg';
import img5 from '../assets/img/portfolio/2024estorilclassics/giuliasprintgta2.jpeg';
import img6 from '../assets/img/portfolio/2024estorilclassics/giulietta.jpeg';

import lsr1 from '../assets/img/portfolio/2024leiriasobrerodas/escortmk1.jpeg';
import lsr2 from '../assets/img/portfolio/2024leiriasobrerodas/i20rally2.jpeg';
import lsr3 from '../assets/img/portfolio/2024leiriasobrerodas/porschediabolique.jpeg';
import lsr4 from '../assets/img/portfolio/2024leiriasobrerodas/alpinea110.jpeg';
import lsr5 from '../assets/img/portfolio/2024leiriasobrerodas/integraleevo.jpeg';

import cmf1 from '../assets/img/portfolio/2024caramulomotorfestival/0F4144A5-8360-4F76-90DA-C5DFB8D9D708_1_105_c.jpeg'
import cmf2 from '../assets/img/portfolio/2024caramulomotorfestival/32F257A0-E441-4C8F-BDAA-B28382D0EFCA_1_105_c.jpeg'
import cmf3 from '../assets/img/portfolio/2024caramulomotorfestival/4DA542A3-08CB-47FF-BAFF-3E1EBB4AB530_1_105_c.jpeg'
import cmf4 from '../assets/img/portfolio/2024caramulomotorfestival/50E0971D-725F-4767-9D2E-52D6B25B3174_1_105_c.jpeg'
import cmf5 from '../assets/img/portfolio/2024caramulomotorfestival/79AB7248-176A-411A-BE1D-1C0019812874_1_105_c.jpeg'
import cmf6 from '../assets/img/portfolio/2024caramulomotorfestival/B40EFF8E-3FF5-4045-84F6-B522DB575AD1_1_105_c.jpeg'
import cmf7 from '../assets/img/portfolio/2024caramulomotorfestival/C7447EC9-9379-495C-9EE9-8E3DBA5E1839_1_105_c.jpeg'
import cmf8 from '../assets/img/portfolio/2024caramulomotorfestival/FC970E30-5A30-4174-B0C5-1EF384F9966F_1_201_a.jpeg'


const imageGroups = [
    {
        year: 2024,
        works: [
            {
                title: "Estoril Classics",
                images: [img2, img3, img1, img4, img5, img6]
            },
            {
                title: "Leiria Sobre Rodas",
                images: [lsr3, lsr1, lsr4, lsr2, lsr5]
            },
            {
                title: "Caramulo Motorfestival",
                images: [cmf1, cmf2, cmf3, cmf4, cmf5, cmf6, cmf7, cmf8]
            }
        ]
    },
];

function Portfolio() {
    return (
        <>
           
           <section id="portfolio-section" className="*:selection:bg-primary *:selection:text-white flex flex-col justify-center px-5 min-h-screen text-white">
                <div id="portfolio-page" className="flex justify-center mt-20">
                    <div className="grid grid-cols-1 w-5/6 md:w-4/5 lg:w-2/3 text-start">
                        {imageGroups.map((group, groupIndex) => (
                            <div key={groupIndex} className="mb-10">
                                <h2 className="font-six-caps leading-none text-6xl font-bold uppercase mt-0 mb-6">{group.year}</h2>

                                {group.works.map((work, workIndex) => (
                                    <div key={workIndex} className="mb-20">
                                        <h3 className="font-raleway leading-none text-2xl font-bold uppercase mt-0 mb-4 text-end">{work.title}</h3>
                                        <ResponsiveMasonry columnsCountBreakPoints={{250: 2, 600: 3}}>
                                            <Masonry gutter="30px">
                                                {work.images.length > 0 ? (
                                                    work.images.map((image, i) => (
                                                        <img
                                                            key={i}
                                                            src={image}
                                                            style={{ width: "100%", display: "block" }} 
                                                            alt={`${work.title} image ${i + 1}`}
                                                        />
                                                    ))
                                                ) : (
                                                    <p className="text-center">No images available for this work.</p>
                                                )}
                                            </Masonry>
                                        </ResponsiveMasonry>
                                    </div>
                                ))}

                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section id="now-insp" className="px-5 flex flex-col justify-center items-center text-center *:selection:bg-primary *:selection:text-white">
                <p className="font-raleway text-xs font-bold uppercase text-justify text-white">
                    for more information contact me at
                    <a href="mailto:rd810silva@gmail.com" rel="noreferrer" target="_blank" className="ml-1 lowercase bg-blue-600 hover:bg-blue-700">rd810silva<span className="font-sarabun">@</span>gmail.com</a>
                </p>
            </section>
        </>
    );
}

export default Portfolio;