import React from "react";
import { Link } from "react-router-dom";

// Define the last updated date
const lastUpdated = "October 1, 2024"; // Update this when necessary

function Now() {
    return (
        <>
            <section id="now" className="*:selection:bg-primary *:selection:text-white flex flex-col justify-center px-5 min-h-screen text-white">
                <div id="now-page" className="flex justify-center mt-20">
                    <div className="grid grid-cols-1 sm:w-2/3 text-start">
                        <div className="col-span-4 flex flex-col items-start">
                        <div className="">
                                <p className='pt-3 font-raleway text-4xl font-bold uppercase text-justify text-white'>
                                    <Link to="/" className="">&larr;</Link>
                                </p>
                            </div>
                            <p className="font-raleway text-sm font-bold mb-2"> 
                                <span className="uppercase">What I'm Doing Now </span>
                            </p>

                            {/* current activities */}
                            <div className="mb-12">
                                {/* <p className="font-raleway text-md font-bold uppercase">Current Focus</p> */}
                                <ul className="list-none">
                                    <li>
                                        <p className="font-raleway text-md font-normal text-justify mt-2">
                                            Starting a Masters in Computer Science and Engineering at UBI;
                                        </p>
                                    </li>
                                    <li>
                                        <p className="font-raleway text-md font-normal text-justify mt-2">
                                            Preparing a demo paper for ECIR2025 and working on improving the correspondent Python package. Writing is a top priority, and, surprisingly, I quite enjoy it;
                                        </p>
                                    </li>
                                    <li>
                                        <p className="font-raleway text-md font-normal text-justify mt-2">
                                            Been trying to read more lately, right now following a roadmap to understand LLMs (thanks to some great advice):
                                            Started with this article:&nbsp;
                                            <i><a href="https://writings.stephenwolfram.com/2023/02/what-is-chatgpt-doing-and-why-does-it-work/" target="_blank" className="link" rel="noreferrer">What Is ChatGPT Doing and Why Does It Work?</a></i> It breaks down some of the core concepts that will be relevant later (or at least introduces them), as well as it explains the GPTs (haven't finished by the time of this update). Next on the list is understanding the UAT. I was told that on the surface, UAT is quite simple, but it has deeper implications. Then, it was recommend to build a simple feedforward network (in Python), for some hands-on learning. Finally (but won't end here for sure), I will read <i><a href="https://arxiv.org/pdf/1706.03762" className="link" target="_blank" rel="noreferrer">Attention is All You Need</a></i>, break it down and pick up concepts (<i>embeddings</i>, <i>RNNs</i>, ...);
                                        </p>
                                    </li>
                                    <li>
                                        <p className="font-raleway text-md font-normal text-justify mt-2">
                                            Besides this, I'm reading <i>Man's Search for Meaning</i> by Viktor E. Frankl; 
                                        </p>
                                    </li>
                                    <li className="pt-4">
                                        <p className="font-raleway text-md font-normal text-justify mt-2">
                                            <i>
                                            <a href="https://open.spotify.com/track/1qKwS9v2xHjcAY5G6DX5D1?si=894accd9e0944207" className="link" target="_blank" rel="noreferrer">
                                                Canção de madrugar - Rua da Saudade
                                            </a>
                                            </i>
                                        </p>
                                    </li>
                                </ul>
                                
                            </div>

                            {/* last updated */}
                            <div className="mb-8">
                                <p className="font-raleway text-sm font-normal  text-justify">
                                    Last update was <span className="font-normal">{lastUpdated}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="now-insp" className="px-5 flex flex-col justify-center items-center text-center">
                <p className="font-raleway text-xs font-bold uppercase text-justify text-white">
                    the creation of this <span className="bg-blue-600">/now</span > page was inspired by Derek Sivers' - 
                    <a href="https://sive.rs/nowff" rel="noreferrer" target="_blank" className="text-black bg-white ml-1">sive.rs/nowff</a>
                </p>
            </section>
        </>
    );
}

export default Now;